import React, { ReactElement, ReactNode, useState } from 'react';
import ModalComponent from '../ui/Modal/Modal';
import { ConfirmModalContent } from '../ui';
import { ConfirmProps } from '../ui/Modal/ConfirmModalContent';

const createPromise = async () => {
  let resolve: undefined | ((value: unknown) => void) = undefined;
  let promise = new Promise((res, _) => {
    resolve = res;
  });

  return {
    promise,
    resolve,
  };
};

export type ConfirmationProps = {
  title?: string | ReactNode;
  description?: string;
  confirmText?: string;
  rejectsText?: string;
  [x: string]: any;
};

export const useConfirm = (
  Content?: ({
    title,
    onConfirm,
    onCancel,
    confirmText,
    rejectsText,
    description,
  }: ConfirmProps & any) => ReactElement
) => {
  const [open, setOpen] = useState(false);
  const [resolver, setResolver] = useState<{
    resolve: ((value: PromiseLike<unknown> | unknown) => void) | undefined;
  }>({ resolve: undefined });
  const [options, setOptions] = useState<ConfirmationProps>();

  const getConfirmation = async (options?: ConfirmationProps) => {
    setOptions(options);
    setOpen(true);
    const { promise, resolve } = await createPromise();
    setResolver({ resolve });
    return promise;
  };

  const onClick = async (status: boolean) => {
    setOpen(false);
    resolver?.resolve && resolver?.resolve(status);
  };

  const Confirmation = () => (
    <ModalComponent open={open} setOpen={onClick}>
      {Content ? (
        <Content
          {...options}
          onConfirm={() => onClick(true)}
          onCancel={() => onClick(false)}
        />
      ) : (
        <ConfirmModalContent
          {...options}
          onConfirm={() => onClick(true)}
          onCancel={() => onClick(false)}
        />
      )}
    </ModalComponent>
  );

  return { getConfirmation, Confirmation };
};
