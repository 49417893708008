import Path from '../utils/Path';

/**
 * Пример использования:
 * export const myPath = api('/my/:param/:optionalParam?');
 * См. примеры из библиотеки https://github.com/pillarjs/path-to-regexp
 */
const api = Path.preset({ basename: '' });

export const comments = api('/comments');

export const classes = api('/limm-control/dictionaries/classes');

export const objectTypes = api('/limm-control/dictionaries/check-object-types');

export const ceilingsRef = (id?: string) =>
  api(`/limm-control/decisions/${id}/ceiling-refs`);

export const ceilingsProduct = (id?: string) =>
  api(`/limm-control/decisions/${id}/products`);

export const ceilingsCustomer = (id?: string) => {
  return api(`/limm-control/decisions/${id}/customers`);
};

export const ceilingsMutation = (id?: string) =>
  api(`/limm-control/decisions/${id}/ceilings`);

export const ceiling = (id?: string, ceilingId?: string | null) =>
  api(`/limm-control/decisions/${id}/ceilings/${ceilingId}`);

export const solutionLimitsRecount = (id?: string) =>
  api(`/limm-control/decisions/${id}/recalculate`);

export const cloneCreditSolution = (id?: string) =>
  api(`/limm-control/decisions/${id}/clone`);

export const customer = (id?: string) =>
  api(`/limm-control/dictionaries/customers/${id}`);

export const customerMutate = api(`/limm-control/dictionaries/customers`);

export const customerLimit = (id?: string) =>
  api(`/limm-control/dictionaries/customers/${id}/limits/search`);

export const customerBlock = (id?: string) =>
  api(`/limm-control/dictionaries/customers/${id}/locks/search`);

export const addCustomerLocks = (id?: string) =>
  api(`/limm-control/dictionaries/customers/${id}/locks`);

export const unlockCustomer = (customerId?: string) =>
  api(`/limm-control/dictionaries/customers/${customerId}/locks/unlock`);

export const customerLimitUsage = (id?: string) =>
  api(`/limm-control/limits/${id}/contracts/low-contracts/search`);

export const customerDebt = (id?: string) =>
  api(`/limm-control/contracts/low-contracts/${id}/repayments/search`);

export const customersSearch = api(
  '/limm-control/dictionaries/customers/search'
);

export const customerDealsSearch = (id?: string) =>
  api(`/limm-control/dictionaries/customers/${id}/deal-contracts/search`);

export const customerContractsSearch = (id?: string) =>
  api(`/limm-control/dictionaries/customers/${id}/doc-contracts/search`);

export const customersGroupSearch = api(
  '/limm-control/dictionaries/groups/search'
);

export const subgroupSearch = (id?: string) =>
  api(`/limm-control/dictionaries/groups/${id}/sub-groups`);

export const classesSearch = () => api(`/limm-control/dictionaries/classes`);
export const limitKindsSearch = () =>
  api(`/limm-control/dictionaries/limit-kinds`);

export const limitTypesSearch = () => api(`/limm-control/limits/types`);

export const currencySearch = api(
  '/limm-control/dictionaries/currencies/search'
);

export const currencyMutate = api('/limm-control/dictionaries/currencies');

export const currencyRateSearch = api(
  '/limm-control/dictionaries/currencies/rates/search'
);

export const currencyRateMutate = api(
  '/limm-control/dictionaries/currencies/rates'
);

export const productSearch = api('/limm-control/dictionaries/products/search');

export const docProducts = api('/limm-control/contracts/product');

export const searchDecision = api('/limm-control/decisions/search');

export const createCreditSolution = api('/limm-control/decisions');
export const updateCreditSolution = (solutionID?: string) =>
  api(`/limm-control/decisions/${solutionID}`);

export const updateCeiling = (solutionID?: string, ceilingId?: string) =>
  api(`/limm-control/decisions/${solutionID}/ceilings/${ceilingId}`);

export const getGeneralData = (id?: string) =>
  api(`/limm-control/decisions/${id}`);

export const getDecisionAuthCodes = (id?: string) =>
  api(`/limm-control/decisions/${id}/auth-codes`);

export const getSubLimits = (id?: string) =>
  api(`/limm-control/decisions/${id}/sub-limits`);

export const getCeilings = (id?: string) =>
  api(`/limm-control/decisions/${id}/ceilings`);

export const activateCreditSolution = (id?: string) =>
  api(`/limm-control/decisions/${id}/activate`);

export const getSublimitData = (decisionId: string, subLimitUUID: string) =>
  api(`/limm-control/decisions/${decisionId}/sub-limits/${subLimitUUID}`);

export const solutionLimitsSearch = (decisionId: string) =>
  api(`/limm-control/decisions/${decisionId}/limits/search`);

export const product = (id?: string) =>
  api(`/limm-control/dictionaries/products/${id}`);

export const productMutate = api(`/limm-control/dictionaries/products`);

export const group = (id?: string) =>
  api(`/limm-control/dictionaries/groups/${id}`);

export const taskSearch = api(`/limm-control/tasks`);

export const taskAssigners = api(`/limm-control/tasks/assigners`);

export const task = (id?: string) => api(`/limm-control/tasks/${id}`);

export const taskDecisions = (id?: string) =>
  api(`/limm-control/tasks/${id}/decisions`);

export const taskEntities = (id?: string) =>
  api(`/limm-control/tasks/${id}/entities`);

export const taskAssign = (id?: string) =>
  api(`/limm-control/tasks/${id}/claim`);

export const taskComplete = (id?: string) =>
  api(`/limm-control/tasks/${id}/complete`);

export const taskRefuse = (id?: string) =>
  api(`/limm-control/tasks/${id}/refuse`);

export const taskTypes = api(`/limm-control/dictionaries/task-types`);

export const systemSearch = api(`/limm-control/dictionaries/systems/search`);

export const limitCancellation = (limitId?: string) =>
  api(`/limm-control/limits/${limitId}/cancellations`);

export const limitLock = (limitId?: string) =>
  api(`/limm-control/limits/${limitId}/locks`);

export const limitRecount = (contractId?: string) =>
  api(`/limm-control/contracts/top-contracts/${contractId}/recalculate`);

export const pledgeById = (pledgeId?: string) =>
  api(`/limm-control/pledges/${pledgeId}`);

export const pledgesKinds = api(`/limm-control/pledges/kinds`);
export const pledgesTypes = api(`/limm-control/dictionaries/pledge-types`);
export const pledgesStatuses = api(`/limm-control/pledges/statuses`);

export const pledge = api(`/limm-control/pledges`);

export const finOperations = api(`/limm-control/dictionaries/fin-operations`);

export const permissions = api(`/auth/permission`);

export const customersWithTopContracts = api(
  '/limm-control/decisions/customers'
);

export const downloadCreditSolution = api(`/limm-control/decisions/statements`);

export const uploadCreditSolutionDoc = (id?: string) =>
  api(`/limm-control/decisions/${id}/ceilings/statements/`);

export const downloadContractsTop = api(
  `/limm-control/contracts/top-contracts/statements`
);

export const downloadBalance = api(
  `/limm-control/contracts/top-contracts/statements/balance`
);

export const downloadContractsLow = api(
  `/limm-control/contracts/low-contracts/statements`
);

export const downloadCustomers = api(
  `/limm-control/dictionaries/customers/statements`
);

export const downloadProducts = api(
  `/limm-control/contracts/products/statements`
);

export const contractsProducts = api(`/limm-control/contracts/product`);

export const taskStatuses = api(`/limm-control/tasks/statuses`);

export const downloadCurrency = api(
  `/limm-control/contracts/products/currencies`
);

export const downloadCurrencyRate = api(
  `/limm-control/contracts/products/currencies/rates`
);

export const downloadCustomerLimits = (id?: string) =>
  api(`/limm-control/dictionaries/customers/${id}/limits/statements`);

export const downloadLimitUsage = (id?: string) =>
  api(`/limm-control/limits/${id}/contracts/low-contracts/statements`);

export const downloadLocks = (id?: string) =>
  api(`/limm-control/dictionaries/customers/${id}/locks/statements`);

export const downloadTasks = api(`/limm-control/task/statement`);

export const getSublimitPledges = (id?: string, ceilingId?: string) =>
  api(`/limm-control/decisions/${id}/ceilings/${ceilingId}/pledges`);

export const downloadDecisionStatement = (id?: string) =>
  api(`/limm-control/decisions/${id}/ceilings/statements`);

export const actions = (id?: string) =>
  api(`/limm-control/decisions/${id}/ceilings/statements/actions`);

export const unlock = (id?: string) =>
  api(`/limm-control/decisions/${id}/ceilings/statements/locks`);

export const participants = () => api(`/limm-control/participants`);

//export const contracts = () => api(`/limm-control/contracts`);
