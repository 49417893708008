import { Ability } from '@casl/ability';

// Альтернативный пример:
// type Action = 'manage' | 'create' | 'read' | 'update' | 'delete';
export type Action =
  | 'update'
  | 'write'
  | 'read'
  | 'delete'
  | 'export'
  | 'block'
  | 'close'
  | 'recount'
  | 'activate'
  | 'cancel';

// Альтернативный пример:
// type Subject = 'all' | 'Article' | 'User' | 'Comment';
export type Subject =
  | 'all'
  | 'kr'
  | 'dvu'
  | 'dnu'
  | 'dictionary'
  | 'task'
  | 'admin'
  | 'roles'
  | 'system'
  | 'limit'
  | 'client'
  | 'report';

export type AppAbility = Ability<[Action, Subject]>;

interface AbilityConfigOption {
  claimName: string;
  subject: Subject;
  action: Action;
}

export const ABILITY_CONFIG: AbilityConfigOption[] = [
  //task
  {
    claimName: 'CHECK_DOC_TASK',
    subject: 'task',
    action: 'read',
  },
  {
    claimName: 'CREDIT_DECISIONS_FORMALIZATION_TASK',
    subject: 'task',
    action: 'read',
  },
  {
    claimName: 'KR_DEVIATION_TASK',
    subject: 'task',
    action: 'read',
  },
  {
    claimName: 'REASSIGN_TASK',
    subject: 'task',
    action: 'update',
  },
  {
    claimName: 'ASSIGN_TASK',
    subject: 'task',
    action: 'write',
  },
  {
    claimName: 'OVERLIMIT_LIFETIME_TASK',
    subject: 'task',
    action: 'read',
  },
  {
    claimName: 'READ_TASK',
    subject: 'task',
    action: 'read',
  },
  {
    claimName: 'UNATTACHED_DVU_TASK',
    subject: 'task',
    action: 'read',
  },
  {
    claimName: 'VALIDATION_DVU_TASK',
    subject: 'task',
    action: 'read',
  },

  //kr
  {
    claimName: 'READ_KR',
    subject: 'kr',
    action: 'read',
  },
  {
    claimName: 'WRITE_KR',
    subject: 'kr',
    action: 'write',
  },
  {
    claimName: 'RECOUNT_DECISION',
    subject: 'kr',
    action: 'recount',
  },

  //dvu
  {
    claimName: 'READ_DOC',
    subject: 'dvu',
    action: 'read',
  },
  {
    claimName: 'WRITE_DOC',
    subject: 'dvu',
    action: 'write',
  },
  {
    claimName: 'CANCEL_DOC',
    subject: 'dvu',
    action: 'cancel',
  },
  {
    claimName: 'CLOSE_DOC',
    subject: 'dvu',
    action: 'close',
  },
  {
    claimName: 'WRITE_REPAYMENT',
    subject: 'dvu',
    action: 'write',
  },

  //dnu
  {
    claimName: 'READ_DEAL',
    subject: 'dnu',
    action: 'read',
  },
  {
    claimName: 'WRITE_DEAL',
    subject: 'dnu',
    action: 'write',
  },
  {
    claimName: 'CANCEL_DEAL',
    subject: 'dnu',
    action: 'cancel',
  },
  {
    claimName: 'CLOSE_DEAL',
    subject: 'dnu',
    action: 'close',
  },
  {
    claimName: 'WRITE_REPAYMENT',
    subject: 'dnu',
    action: 'write',
  },
  {
    claimName: 'DELETE_DEAL',
    subject: 'dnu',
    action: 'delete',
  },

  //dictionary
  {
    claimName: 'READ_DICTIONARY',
    subject: 'dictionary',
    action: 'read',
  },
  {
    claimName: 'WRITE_DICTIONARY',
    subject: 'dictionary',
    action: 'write',
  },
  {
    claimName: 'ALTER_DICTIONARY',
    subject: 'dictionary',
    action: 'update',
  },
  {
    claimName: 'BLOCK_CLIENT',
    subject: 'client',
    action: 'block',
  },

  //all
  {
    claimName: 'EXPORT',
    subject: 'all',
    action: 'export',
  },

  //system roles
  {
    claimName: 'READ_SYSTEM_ROLE_SETTINGS',
    subject: 'roles',
    action: 'read',
  },
  {
    claimName: 'WRITE_SYSTEM_ROLE_SETTINGS',
    subject: 'roles',
    action: 'write',
  },
  {
    claimName: 'ALTER_SYSTEM_ROLE_SETTINGS',
    subject: 'roles',
    action: 'update',
  },

  //system
  {
    claimName: 'READ_SYSTEM_SETTINGS',
    subject: 'system',
    action: 'read',
  },
  {
    claimName: 'WRITE_SYSTEM_SETTINGS',
    subject: 'system',
    action: 'write',
  },
  {
    claimName: 'ALTER_SYSTEM_SETTINGS',
    subject: 'system',
    action: 'update',
  },

  //limit
  {
    claimName: 'ACTIVATE_LIMIT',
    subject: 'limit',
    action: 'activate',
  },
  {
    claimName: 'BLOCK_LIMIT',
    subject: 'limit',
    action: 'block',
  },
  {
    claimName: 'CANSEL_LIMIT',
    subject: 'limit',
    action: 'cancel',
  },
  {
    claimName: 'RECOUNT_LIMIT',
    subject: 'limit',
    action: 'recount',
  },
  // report
  {
    claimName: 'READ_REPORT',
    subject: 'report',
    action: 'read',
  },
];
